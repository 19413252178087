<template>
  <div class="product-create create-form" v-if="getPermission('part:create')">
    <v-form
      ref="certificateForm"
      v-model.trim="formValid"
      lazy-validation
      v-on:submit.stop.prevent="onSubmit"
    >
      <v-row>
        <v-col cols="12" class="pt-0">
          <v-container fluid class="white-background main-create-page">
            <div class="p-5 pb-0">
              <v-row>
                <v-col cols="6" class="pt-0">
                  <h1 class="form-title d-flex">
                    <template v-if="certificateId">Update</template>
                    <template v-else>Create</template> Certificate 
                    
                          <Barcode v-if="certificateCreate && certificateCreate.certificate_no"
                            class="cursor-pointer ml-2"
                            route="certificate.detail"
                            :barcode="certificateCreate && certificateCreate.certificate_no"
                          ></Barcode>
                  </h1>
                </v-col>
                <v-col cols="6" class="pt-0 text-right">
                  <v-btn
                    :disabled="!formValid || formLoading"
                    :loading="formLoading"
                    class="mx-2 custom-bold-button text-white"
                    color="cyan"
                    v-on:click="onSubmit"
                  >
                    <template v-if="certificateId">Update</template>
                    <template v-else>Save</template> Certificate
                  </v-btn>
                  <v-btn
                    v-on:click="goBack"
                    :disabled="formLoading"
                    class="mx-2 custom-grey-border custom-bold-button"
                    outlined
                    >Cancel
                  </v-btn>
                </v-col>
              </v-row>
            </div>
            <CertificateCreate
              :certificate-id="certificateId"
              :barcodeData="barcode"
              :updateMode="updateMode"
              :certificateCreateData="certificateCreate"
              v-on:saveCertificate="updateTagging"
            ></CertificateCreate>
          </v-container>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ProductMixin from "@/core/plugins/product-mixin";
import { ProductBrandEventBus } from "@/core/lib/product.brand.lib";

import {
  POST,
  GET,
  PUT,
  CLEAR_ERROR,
  UPDATE_ERROR,
} from "@/core/services/store/request.module";
// import PartCreate from "@/view/pages/part/partials/Create.vue";
import CertificateCreate from "@/view/pages/certificate/partials/Create.vue";
//import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import Barcode from "@/view/pages/partials/Barcode.vue";
export default {
  mixins: [CommonMixin, ProductMixin],
  name: "certificate-create",
  title: "Create certificate",
  data() {
    return {
      
      barcode: null,
      barcodeDialog: false,
      updateMode: false,
      barcodeSetting: {
        prefix_string: null,
        next_number: null,
        number_length: null,
      },
      parttCreate: null,
      certificateCreate: null,
      certificateId: null,
      duplicateId: null,
    };
  },
  components: {
    CertificateCreate,
    Barcode
  },
  beforeUpdate() {
    this.$store.dispatch(CLEAR_ERROR);
  },
  methods: {
    updateTagging(param) {
      this.certificateCreate = param;
    },
    getCertificate(certificateId) {
      const _this = this;
      return new Promise((resolve, reject) => {
        if (certificateId && certificateId > 0) {
          _this.$store
            .dispatch(GET, { url: "certificate/" + certificateId })
            .then((response) => {
              const Data = response.data;
              resolve(Data);
              /* this.certificateCreate = {
                tagging_id:Data.tagging_id,
                serialNo: Data.serial_no,
                //certificate_no: Data.barcode ? Data.barcode : null,
                nameOfCustomer: Data.customer_name,
                //product_type: Data.product_type,
                //purchase_cost: Data.purchase_cost,
                quotation_no: Data.quotation_no,
                contact_no: Data.contact_no,
                job_no: Data.job_no,
                calibration_schedule: Data.calibration_schedule,
                //selectedMonth:Data.calibration_schedule,
                due_date: Data.due_date,
                calibration_date: Data.calibration_date,
                
                endOfRecurrence: Data.endOfRecurrence,
                endOfRecurrenceDate: Data.endOfRecurrenceDate,
                selectedMonth: Data.calibration_schedule,
                occurrencesInput: Data.occurrencesInput,
                occurrencesDateTime: Data.occurrencesDateTime,
                scheduleDateTable: Data.scheduleDateTable,
                scheduleDay: Data.scheduleDay,
                scheduleStatus: Data.scheduleStatus,
              }; */
              //console.log(this.certificateCreate);
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          resolve({});
        }
      });
    },

    onSubmit() {
      const _this = this;
      if (!_this.$refs.certificateForm.validate()) {
        return false;
      }

      _this.formLoading = true;
      _this.$store.dispatch(CLEAR_ERROR, {});

      let requestType = POST;
      let requestURL = "certificate";
      let Message = "Created.";

      if (_this.certificateId && _this.certificateId > 0) {
        requestType = PUT;
        requestURL = "certificate/" + _this.certificateId;
        Message = "Updated.";
      }

      const TaggingData = _this.certificateCreate;  
      const formData = {
				serial_no: TaggingData.serialNo?TaggingData.serialNo:null,
				tagging_id: TaggingData.tagging_id?TaggingData.tagging_id:null,
				job_no: TaggingData.job_no?TaggingData.job_no:null,
				contact_no: TaggingData.contact_no?TaggingData.contact_no:null,
				quotation_no: TaggingData.quotation_no?TaggingData.quotation_no:0,
				customer_name:TaggingData.nameOfCustomer?TaggingData.nameOfCustomer:null,
        calibration_schedule: TaggingData.selectedMonth?TaggingData.selectedMonth:null,
				due_date: TaggingData.due_date?TaggingData.due_date:0,
				calibration_date:TaggingData.calibration_date?TaggingData.calibration_date:null,
        equipment_type:TaggingData.equipment_type?TaggingData.equipment_type:null,
        reminder_date:TaggingData.reminder_date?TaggingData.reminder_date:null,
        due_date_value:TaggingData.reminder_date_value?TaggingData.reminder_date_value:null,
			};
      
      if(_this.duplicateId){
        formData.duplicate = _this.duplicateId;
      }

      try{
        _this.$store
        .dispatch(requestType, { url: requestURL, data:formData })
        .then(async (response) => {
          _this.certificateId = response.data ? response.data.id : null;

          _this.$router.push(
            _this.getDefaultRoute("certificate", {
              query: {
                status: "all",
              },
            })
          );
        });

        _this.$store.commit(SET_MESSAGE, [
          { model: true, message: "Success ! Taggings has been "+Message },
        ]);
      }
      catch (error) {
				_this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				//_this.pageLoading = false;
        _this.formLoading = false;
			}
      
        // .catch((error) => {
        //   _this.logError(error);
        // })
        // .finally(() => {
        //   _this.formLoading = false;
        // });
    },
    loadContent() {
      
      const _this = this;
      _this
        .getCertificate(_this.certificateId)
        .then((response) => {
          
          if (_this.lodash.isEmpty(response) === false) {
            _this.$store.dispatch(SET_BREADCRUMB, [
              { title: "Certificate", route: "certificate" },
              { title: "Update" },
            ]);

            _this.certificateCreate = {
              tagging_id: response.tagging_id,
              serialNo: response.serial_no,
              certificate_no: response.barcode,
              nameOfCustomer: response.customer_name,
              product_type: response.product_type,
              purchase_cost: response.purchase_cost,
              quotation_no: response.quotation_no,
              contact_no: response.contact_no,
              job_no: response.job_no,
              calibration_schedule: response.calibration_schedule,
              due_date: response.due_date,
              calibration_date: response.calibration_date,
              endOfRecurrence: response.endOfRecurrence,
              endOfRecurrenceDate: response.endOfRecurrenceDate,
              selectedMonth: response.calibration_schedule,
              occurrencesInput: response.occurrencesInput,
              occurrencesDateTime: response.occurrencesDateTime,
              scheduleDateTable: response.scheduleDateTable,
              scheduleDay: response.scheduleDay,
              scheduleStatus: response.scheduleStatus,
              equipment_type: response.equipment_type? response.equipment_type : null,
            };
          }
        })
        .catch((error) => {
          _this.$store.dispatch(UPDATE_ERROR, error);
        });
    },
    loadDuplicateContent() {
      
      const _this = this;
      _this
        .getCertificate(_this.duplicateId)
        .then((response) => {
          //console.log({response})
          if (_this.lodash.isEmpty(response) === false) {
            let certNo = _this.certificateCreate ? _this.certificateCreate.certificate_no : null; 
            _this.certificateCreate = {
              tagging_id: response.tagging_id,
              serialNo: response.serial_no,
              certificate_no: certNo,
              nameOfCustomer: response.customer_name,
              product_type: response.product_type,
              purchase_cost: response.purchase_cost,
              quotation_no: response.quotation_no,
              contact_no: response.contact_no,
              job_no: response.job_no,
              calibration_schedule: response.calibration_schedule,
              due_date: response.due_date,
              calibration_date: response.calibration_date,
              endOfRecurrence: response.endOfRecurrence,
              endOfRecurrenceDate: response.endOfRecurrenceDate,
              selectedMonth: response.calibration_schedule,
              occurrencesInput: response.occurrencesInput,
              occurrencesDateTime: response.occurrencesDateTime,
              scheduleDateTable: response.scheduleDateTable,
              scheduleDay: response.scheduleDay,
              scheduleStatus: response.scheduleStatus,
              equipment_type: response.equipment_type?response.equipment_type : null,
            };
          }
        })
        .catch((error) => {
          _this.$store.dispatch(UPDATE_ERROR, error);
        });
    },
  },
  created() {
    const _this = this;
    _this.certificateId = _this.lodash.toSafeInteger(_this.$route.params.id);
    _this.duplicateId = _this.lodash.toSafeInteger(
      _this.$route.query.duplicate
    );
  },
  mounted() {
    const _this = this;
    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Certificate", route: "certificate" },
      { title: "Create" },
    ]);

    if (
      _this.$route.name == "admin.certificate.update" ||
      _this.$route.name == "engineer.certificate.update"
    ) {
      _this.updateMode = true;

      if (_this.certificateId <= 0) {
        _this.$router.go(-1);
      }

      _this.loadContent();
    }

    if (_this.duplicateId > 0) {
      _this.loadDuplicateContent();
    }

    ProductBrandEventBus.$on("refress-brand", function () {
      this.getCertificate(_this.certificateId);
    });
  },
};
</script>
