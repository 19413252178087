<template>
    <Dialog :commonDialog="cDialog" :dialogWidth="dialogWidth">
      <template v-slot:title>
        <v-layout>
          <v-flex> Manage Calibration Schedule </v-flex>
          <v-flex class="text-right">   
            <v-btn small class="mx-4" v-on:click="createProductCategory">
              <span class="font-size-16 font-weight-600"
                >Add Calibration Schedule</span
              >
            </v-btn>
          </v-flex>
        </v-layout>
      </template>
      <template v-slot:body>
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll custom-box-top-inner-shadow"
          style="max-height: 90vh; position: relative"
        >
          <v-form
            ref="ticketCollecttionForm"
            v-model.trim="formValid"
            lazy-validation
            v-on:submit.stop.prevent="updateProductCategory"
          >
            <v-container class="py-0">
              <v-row class="py-0">
                <v-col md="12" class="py-0">
                  <table class="width-100">
                    <tbody>
                      <tr v-for="(type, index) in categoryList" :key="index">
                        <td width="20">
                            <!--:label="`${get_title()} Month`"-->
                          <v-text-field
                            type="number"
                            v-model.trim="type.value"
                            dense
                            filled
                            label="Add Month"
                            solo
                            flat
                            class="px-4 py-2"
                            hide-details
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            :rules="[
                              validateRules.required(type.value, 'Month'),
                            ]"
                            color="cyan"
                            :min="0"
                            oninput="if(this.value < 0) this.value = 0;"
                          ></v-text-field>
                        </td>
                        <td width="40">
                          <v-text-field
                            v-model.trim="calibrationType"
                            dense
                            filled
                            label="Month"
                            solo
                            flat
                            class="px-4 py-2"
                            hide-details
                            :disabled="true"
                            color="cyan"
                          ></v-text-field>
                        </td>
                        <td width="40" v-if="categoryList.length > 1">
                          <v-tooltip top content-class="custom-top-tooltip">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-on:click="deleteProductCategory(index)"
                                color="deep-orange"
                                v-bind="attrs"
                                v-on="on"
                                >mdi-delete</v-icon
                              >
                            </template>
                            <span>Click here to delete</span>
                          </v-tooltip>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </perfect-scrollbar>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="pageLoading || !formValid"
          :loading="pageLoading"
          class="mx-2 custom-grey-border custom-bold-button white--text"
          color="cyan"
          v-on:click="updateProductCategory"
        >
          Save
        </v-btn>
        <v-btn
          :disabled="pageLoading"
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="$emit('close-dialog', true)"
        >
          Close
        </v-btn>
      </template>
    </Dialog>
  </template>
  <script>
  import Dialog from "@/view/pages/partials/Dialog.vue";
  import { PATCH } from "@/core/services/store/request.module";
  import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
  import ValidationMixin from "@/core/plugins/validation-mixin";
  import CommonMixin from "@/core/plugins/common-mixin";
  import { cloneDeep } from "lodash";
  export default {
    mixins: [ValidationMixin, CommonMixin],
    props: {
      cDialog: {
        type: Boolean,
        required: true,
        default: false,
      },
      moduleType: {
        type: String,
        default: null,
      },
      product: {
        type: Boolean,
        default: false,
      },
      service: {
        type: Boolean,
        default: false,
      },
      category: {
        type: Array,
        default: () => {
          return new Array();
        },
      },
      existCategory: {
        type: String,
        default: null,
      },
    },
    watch: {
      category: {
        deep: true,
        immediate: true,
        handler(param) {
          this.categoryList = cloneDeep(param);
          //Schedule when deleted
          //  let obj = this.categoryList.find(o => o.value === this.type.value);
          //   if(!obj){
          //     this.type.value = null;
          //   }
        },
      },
    },
    data: () => {
      return {
        calibrationType:"Month",
        formValid: true,
        pageLoading: false,
        categoryList: [
          {
            value: null,
          },
        ],
      };
    },
    methods: {
      updateProductCategory() {
        const _this = this;
        const formErrors = _this.validateForm(_this.$refs.ticketCollecttionForm);
  
        if (formErrors.length) {
          for (let i = 0; i < formErrors.length; i++) {
            ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
            _this.pageLoading = false;
          }
          return false;
        }
        _this.pageLoading = true;
        _this.$store
          .dispatch(PATCH, {
            url: "setting/calibration-month",
            data: { calibration_month: _this.categoryList },
          })
          .then(() => {
            _this.$emit("close-dialog", true);
            _this.$emit("get-product-category", true);
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.pageLoading = false;
          });
      },
      createProductCategory() {
        this.categoryList.push({
          value: null,
          id:null,
          value_type:"month"
        });
      },
      deleteProductCategory(index) {
        if (this.categoryList.length > 1) {
          this.categoryList.splice(index, 1);
        }
      },
      get_title() {
        
        if(this.moduleType=='certificate'){
            return "Certificate";
        }
      },
    },
    components: {
      Dialog,
    },
    computed: {
      dialogWidth() {
        return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 50);
      },
    },
  };
  </script>
  