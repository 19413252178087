<template>
  <v-layout v-if="getPermission('part:create')">
    <v-col class="custom-content-container pt-0" cols="12" md="12" sm="12">
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll custom-box-top-inner-shadow product-create-height"
        style="position: relative"
      >
        <v-col md="12" class="pb-0">
          <!-- <v-card flat class="custom-grey-border remove-border-radius"> -->
          <v-card-title
            class="headline lighten-4"
            style="background-color: #d9d9d9"
          >
            <h3 class="font-weight-700 custom-headline color-custom-blue">
              Certificate Information 
            </h3>
          </v-card-title>
          <v-card-text class="p-6 font-size-16">
            <div style="width: 100%" class="d-flex mt-3">
              <div style="width: 50%" class="d-flex ml-2 align-center mb-3">
                <div style="width: 30%">
                  <label
                    class="pr-2 pl-2 font-weight-700 required font-size-16 width-100" 
                    >Job Number</label
                  >
                </div>
                <div style="width: 80%">
                  <v-autocomplete
                    dense
                    filled
                    solo
                    flat
                    class="pr-2 width-100 mb-2"
                    label="Job Number"
                    :items="jobNumbersList"
                    v-model.trim="certificateCreate.job_no"
                    @change="filterEquipments($event,'job_number')"
                    :rules="[validateRules.required(certificateCreate.job_no, 'Job number')]"
                  ></v-autocomplete>

                  <!-- <v-text-field
                    dense
                    filled
                    label="Job Number"
                    solo
                    flat
                    class="pr-2 width-100"
                    color="cyan"
                    v-model.trim="certificateCreate.job_no"
                  ></v-text-field> -->
                </div>
              </div>
              <div style="width: 50%" class="d-flex ml-2 align-center mb-3">
                <div style="width: 30%">
                  <label
                    class="pr-2 pl-2 font-weight-700 required font-size-16 width-100"
                    >Equipment ID</label
                  >
                </div>
                <div style="width: 80%">
                  <v-autocomplete
                    dense
                    filled
                    solo
                    flat
                    class="pr-2 width-100 mb-2"
                    label="Equipment ID"
                    :items="equipmentsList"
                    v-model.trim="certificateCreate.tagging_id"
                    @change="filterData($event)"
                    :rules="[validateRules.required(certificateCreate.tagging_id, 'Equipment ID')]"
                  ></v-autocomplete>
                  <!-- <v-text-field
                    dense
                    filled
                    label="Job Number"
                    solo
                    flat
                    class="pr-2 width-100"
                    color="cyan"
                    v-model.trim="certificateCreate.job_no"
                  ></v-text-field> -->
                </div>
              </div>
              <!-- <div style="width: 50%" class="d-flex ml-2 align-center mb-3">
                <div style="width: 30%">
                  <label
                    class="pr-2 pl-2 font-weight-700 required font-size-16 width-100"
                    >Job Number</label
                  >
                </div>
                <div style="width: 80%">
                  <div style="width: 80%">
                  <v-autocomplete
                    dense
                    filled
                    solo
                    flat
                    class="pr-2 width-100 mb-2"
                    label="Equipment ID"
                    :items="equipmentsList"
                    v-model.trim="certificateCreate.tagging_id"
                    @change="filterData($event)"
                  ></v-autocomplete>
                  
                </div>
                 
                </div>
              </div> -->
              <!-- <div style="width: 50%" class="d-flex">
                <div style="width: 30%">
                  <label
                    class="pr-2 pl-2 font-weight-700 required font-size-16 width-100 text-right"
                    >Equipment ID</label
                  >
                </div>
                <div style="width: 80%">
                  <v-autocomplete
                    dense
                    filled
                    solo
                    flat
                    class="pr-2 width-100 mb-2"
                    label="Equipment ID"
                    :items="equipmentsList"
                    v-model.trim="certificateCreate.tagging_id"
                    @change="filterData($event)"
                  ></v-autocomplete>
                  
                </div>
              </div> -->
            </div>

            <div style="width: 100%" class="d-flex mt-3">
              <div style="width: 50%" class="d-flex ml-2 align-center mb-3">
                <div style="width: 30%">
                  <label
                    class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                    >Serial No.</label
                  >
                </div>
                <div style="width: 80%">
                  <v-text-field
                    dense
                    filled
                    label="Serial No."
                    solo
                    flat
                    class="pr-2 mb-2 width-100"
                    color="cyan"
                    v-model.trim="certificateCreate.serialNo"
                    
                  ></v-text-field>
                </div>
              </div>
              <div style="width: 50%" class="d-flex ml-2 align-center mb-3">
                <div style="width: 30%">
                  <label
                    class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                    >Quotation </label
                  >
                </div>
                <div style="width: 80%">
                  <v-text-field
                    dense
                    filled
                    label="Quotation"
                    solo
                    flat
                    class="pr-2 mb-2 width-100"
                    color="cyan"
                    v-model.trim="certificateCreate.quotation_no"
                    
                  ></v-text-field>
                </div>
              </div>
              <!-- <div style="width: 50%" class="d-flex ml-2 align-center mb-3">
                <div style="width: 30%">
                  <label
                    class="pr-2 pl-2 font-weight-700 font-size-16 width-100 text-right"
                    >Quotation</label
                  >
                </div>
                <div style="width: 80%">
                  <v-text-field
                    dense
                    filled
                    label="Quotation"
                    solo
                    flat
                    class="pr-2 width-100"
                    color="cyan"
                    v-model.trim="certificateCreate.quotation_no"
                  ></v-text-field>
                </div>
              </div> -->
              <!-- <div style="width: 50%" class="d-flex">
                <div style="width: 32%">
                  <label
                    class="pr-2 pl-2 font-weight-700 font-size-16 width-100 text-right"
                    >Certificate Number#</label
                  >
                </div>
                <div style="width: 75%">
                  <h5 style="font-weight: 800"></h5>
                </div>
              </div> -->
            </div>

            <div style="width: 100%" class="d-flex mb-3">
              <div style="width: 50%" class="d-flex ml-2 align-center">
                <div style="width: 30%">
                  <label
                    class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                    >Customer</label
                  >
                </div>
                <div style="width: 80%">
                  <v-text-field
                    dense
                    filled
                    label="Customer"
                    solo
                    flat
                    class="pr-2 width-100"
                    color="cyan"
                    v-model.trim="certificateCreate.nameOfCustomer"
                  ></v-text-field>
                </div>
              </div>
              <div style="width: 50%" class="d-flex ml-2 align-center">
                <div style="width: 27%">
                  <label
                    class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                    >Contact Number</label
                  >
                </div>
                <div style="width: 71%">
                  <PhoneTextField
                    hide-details
                    v-model.trim="certificateCreate.contact_no"
                  >
                  </PhoneTextField>
                </div>
              </div>
            </div>

            <div style="width: 100%" class="d-flex mb-3">
              <div style="width: 50%" class="d-flex ml-2 align-center">
                <div style="width: 29%">
                  <label
                    class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                    >Equipment Type</label
                  >
                </div>
                <div style="width: 78%">
                  <v-text-field
                    dense
                    filled
                    label="Equipment Type"
                    solo
                    flat
                    class="pr-2 width-100"
                    color="cyan"
                    v-model.trim="certificateCreate.equipment_type"
                  ></v-text-field>
                </div>
              </div>
              
            </div>

            <v-card-title
              class="headline lighten-4 mt-5"
              style="background-color: #d9d9d9"
            >
              <div
                class="width-100 my-auto py-0 d-flex justify-space-between font-weight-700 custom-headline color-custom-blue"
              >
                <span>SCHEDULE</span>
                <!-- <v-btn
                  class="mx-2 custom-grey-border custom-bold-button white--text"
                  color="cyan"
                  v-on:click="dialog = !dialog"
                  ><v-icon>mdi-calendar</v-icon>
                  Show Calendar
                </v-btn> -->
              </div>
            </v-card-title>

            <div style="width: 100%" class="d-flex mt-5">
              <div style="width: 50%" class="d-flex ml-2 align-center">
                <div style="width: 30%">
                  <label
                    class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                    >Calibration Schedule</label
                  >
                </div>
                <div style="width: 75%">
                  <!-- <v-autocomplete
                    dense
                    filled
                    solo
                    flat
                    class="pr-2 width-100 mb-2"
                    v-model="certificateCreate.selectedMonth"
                    label="Select Month"
                    :items="calibrationSchedules"
                    @change="updateAvailableDates"
                  ></v-autocomplete> -->
                  <!-- selectMonth -->
                  
                  <v-autocomplete
                    :items="calibrationSchedules"
                    v-model="certificateCreate.selectedMonth"
                    hide-details
                    clearable
                    flat
                    solo
                    dense
                    label="Select Month"
                    color="cyan"
                    item-color="cyan"
                    class="pt-0 mt-0"
                    @change="updateAvailableDates"
                    item-text="text"
                    item-value="value"
                    v-on:click:clear="clearAll"
                    append-outer-icon="mdi-cog"
                    v-on:click:append-outer="
                      manageCalibrationDateDialog = true
                    "
                  >
                    <!-- <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          class="text-capitalize font-weight-500 font-size-16"
                          >{{ item.text }}</v-list-item-title
                        >
                      </v-list-item-content>
                    </template> -->
                  </v-autocomplete>
                </div>
              </div>
              <div style="width: 50%" class="d-flex ml-2 align-center">
                <div style="width: 30%">
                  <label
                    class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                    >Due Date</label
                  >
                </div>
                <div style="width: 75%">
                  <!-- <v-autocomplete
                    dense
                    filled
                    solo
                    flat
                    class="pr-2 width-100 mb-2"
                    label="Select Options"
                    :items="select_year"
                    v-model.trim="certificateCreate.due_date"
                  ></v-autocomplete> -->
                  <v-autocomplete
                    :items="calibrationDueDates"
                    v-model="certificateCreate.due_date"
                    hide-details
                    clearable
                    flat
                    solo
                    dense
                    label="Select Option"
                    color="cyan"
                    item-color="cyan"
                    class="pt-0 mt-0"
                    item-text="text"
                    item-value="value"
                    @change="calculateDueDate($event)"
                    v-on:click:clear="clearAll"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          class="text-capitalize font-weight-500 font-size-16"
                          >{{ item.text }} 

                          </v-list-item-title
                        >

                        <v-list-item-title
                          class="text-capitalize font-weight-500 font-size-16"
                          >
                            <template v-if="item.value=='same-day'">
                              {{  dueSameDate?formatDate(dueSameDate):null }}
                            </template>
                            <template v-else-if="item.value=='1-day-before'">
                              {{ duePriorDate?formatDate(duePriorDate):null }}
                            </template>
                          </v-list-item-title
                        >

                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </div>
              </div>
              <!-- <div style="width: 50%" class="d-flex ml-2 align-center">
                <div style="width: 30%">
                  <label
                    class="font-weight-700 font-size-16 width-100 text-right align-center"
                    >Due Date</label
                  >
                </div>
                <div style="width: 75%">
                  <v-autocomplete
                    dense
                    filled
                    solo
                    flat
                    class="pr-2 width-100 mb-2"
                    label="Select Options"
                    :items="select_year"
                    v-model.trim="certificateCreate.due_date"
                  ></v-autocomplete>
                </div>
              </div> -->
            </div>
            <div style="width: 100%" class="d-flex mt-5">
              <div style="width: 50%" class="d-flex ml-2 align-center">
                <div style="width: 28%">
                  <label
                    class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                    >Calibration Date</label
                  >
                </div>
                <div style="width: 69%">
                  <Datepicker
                    dense
                    filled
                    solo
                    flat
                    class="pr-2 width-100 mb-2"
                    :default-date="certificateCreate.calibration_date"
                    v-model="certificateCreate.calibration_date"
                    label="Select Date"
                    @change="calculateDueDate($event,'date')"
                  ></Datepicker>
                </div>
              </div>

              <div style="width: 50%" class="d-flex ml-2 align-center">
                <div style="width: 28%">
                  <label
                    class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                    >Reminder Date</label
                  >
                </div>
                <div style="width: 69%">
                  <template v-if="dueDate">
                    {{ dueDate?formatDate(dueDate):null }}
                  </template>
                  <template v-else>
                    <em>No reminder date</em>
                  </template>
                </div>
              </div>

            </div>

          
            <!-- v-model="certificateCreate.calibration_date" -->
            <!-- <div style="width: 100%" class="d-flex mt-5">
              <div style="width: 50%" class="d-flex ml-2 align-center">
                <div style="width: 30%">
                  <label
                    class="pr-2 pl-2 font-weight-800 font-size-18 width-100"
                    >End of Recurrence</label
                  >
                </div>
              </div>
            </div>

            <div style="width: 100%" class="d-flex mt-5">
              <div style="width: 50%" class="d-flex ml-2 align-center">
                <div style="width: 30%" class="mx-4">
                  <v-radio-group>
                    <v-radio
                      v-model.trim="certificateCreate.endOfRecurrence"
                      color="cyan"
                      label="End after"
                      value="1"
                    ></v-radio>
                    <v-radio
                      v-model.trim="certificateCreate.endOfRecurrenceDate"
                      color="cyan"
                      label="End after"
                      value="2"
                    ></v-radio>
                  </v-radio-group>
                </div>
                <div style="width: 75%" class="mx-4">
                  <v-flex class="d-flex align-center">
                    <v-text-field
                      dense
                      filled
                      label=""
                      solo
                      flat
                      class="pr-2 width-100 mb-3"
                      v-model.trim="certificateCreate.occurrencesInput"
                    ></v-text-field>
                    <p>Occurrences</p>
                  </v-flex>
                  <Datepicker
                    dense
                    filled
                    solo
                    flat
                    class="pr-2 width-100"
                    v-model="certificateCreate.occurrencesDateTime"
                    label="Select Month"
                  ></Datepicker>
                </div>
              </div>
            </div>

            <v-row>
              <v-col md="12">
                <v-row class="align-center">
                  <v-col md="2">
                    <v-btn
                      class="mx-2 custom-grey-border custom-bold-button white--text"
                      color="cyan"
                      v-on:click="dialog = !dialog"
                    >
                      Show Schedule
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row> -->
          </v-card-text>
          <br />
        </v-col>
      </perfect-scrollbar>
    </v-col>

    <!-- <Dialog :common-dialog="dialog">
      <template v-slot:title
        >Certificate Schedule
        <span style="font-size: 16px">(1) 01/10/2023 - 01/10/2023</span>
      </template>
      <template v-slot:body>
        <v-form ref="verificationForm" lazy-validation>
          <v-row class="mx-4">
            <v-col md="5">
              <div v-if="availableDates">
                <div v-if="availableDates">
                  <Datepicker
                    customClass="mb-3"
                    v-for="date in availableDates"
                    :key="date"
                    dense
                    filled
                    solo
                    flat
                    :default-date="parseDate(date) || defaultDate"
                  ></Datepicker>
                </div>
              </div>
            </v-col>
            <v-col md="5" class="my-auto py-0">
              <div v-if="availableDates">
                <v-text-field
                  dense
                  filled
                  label="Day"
                  solo
                  flat
                  class="pr-2 width-100 mb-2"
                  color="cyan"
                  v-for="(day, index) in availableDates"
                  :key="index"
                  disabled
                  :value="getDayFromDate(day)"
                ></v-text-field>
              </div>
            </v-col>
            <v-col md="2">
              <v-switch
                hide-details
                inset
                color="cyan"
                class="mb-4"
                v-for="i in parseInt(certificateCreate.selectedMonth)"
                :key="i"
                v-model="certificateCreate.scheduleStatus"
              ></v-switch>
            </v-col>
          </v-row>
        </v-form>
      </template>
      <template v-slot:action>
        <v-btn
          class="mx-2 custom-grey-border custom-bold-button white--text"
          color="cyan"
          v-on:click="dialog = false"
        >
          Close
        </v-btn>
      </template>
    </Dialog> -->

    <ManageUnit
      :u-dialog="manageUnitDialog"
      :unit="unitList"
      v-on:close-dialog="manageUnitDialog = false"
      
    ></ManageUnit>

    <template v-if="manageCalibrationDateDialog">
      <ManageCalibrationDate
        Equipment
        :c-dialog="manageCalibrationDateDialog"
        :category="calibrationSchedules"
        v-on:close-dialog="manageCalibrationDateDialog = false"
        v-on:get-product-category="get_options"
        module-type="certificate"
      ></ManageCalibrationDate>
    </template>

  </v-layout>
</template>

<script>
//import Dialog from "@/view/pages/partials/Dialog.vue";
import moment from "moment-timezone";
import CommonMixin from "@/core/plugins/common-mixin";
import WarrantyMixin from "@/core/lib/warranty/warranty.mixin";
import ProductMixin from "@/core/plugins/product-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
//   import FileTemplate from "@/view/pages/partials/FileTemplate.vue";
import ManageUnit from "@/view/pages/partials/Manage-Product-Unit.vue";
import Datepicker from "@/view/pages/partials/Datepicker.vue";
import PhoneTextField from "@/view/pages/partials/PhoneTextField";
import { map,filter } from "lodash";
import AppConfiguration from "@/core/config/app.config";
import ManageCalibrationDate from "@/view/pages/partials/Manage-Calibration-Date.vue";
import {find} from "lodash";

import {
  QUERY,
  UPDATE_ERROR,
  CLEAR_ERROR,
} from "@/core/services/store/request.module";
/* import { map, compact } from "lodash"; */

export default {
  name: "product-create",
  mixins: [CommonMixin, WarrantyMixin, ProductMixin, ValidationMixin],
  props: {
    //job-numbers
    // jobNumbersList: {
    //   type: Array,
    //   default: () => {
    //     return [];
    //   },
    // },
    certificateId:{
      type: Number,
      default: 0,
    },
    productId: {
      type: [Number, String],
      default: null,
    },
    barcodeData: {
      type: String,
      default: null,
    },
    updateMode: {
      type: Boolean,
      default: false,
    },
    certificateCreateData: {
      type: Object,
      default: () => {
        return new Object();
      },
    },
  },
  beforeUpdate() {
    this.$store.dispatch(CLEAR_ERROR);
  },
  components: {
    //   FileTemplate,
    ManageUnit,
    Datepicker,
   // Dialog,
    //AutoCompleteInput,
    PhoneTextField,
    ManageCalibrationDate
  },
  data() {
    return {
      manageCalibrationDateDialog: false,
      dueDate:"",
      dueSameDate:null,
      duePriorDate:null,
      calibrationSchedules:[],
      calibrationDueDates:[],
      jobNumbersList:[],
      equipmentsList:[],
      selectedDate: null,
      dialog: false,
      defaultDate: moment().startOf("day").format("YYYY-MM-DD"),
      proDuplicateId: null,
      timeoutLimit: 500,
      timeout: null,
      unitList: new Array(),
      barcode: null,
      manageUnitDialog: false,
      pageLoading: true,
      certificateCreate: {
        tagging_id:null,
        serialNo: null,
        certificate_no: null,
        nameOfCustomer: null,
        quotation_no: null,
        contact_no: "+65-67537898",
        job_no: null,
        due_date: '1-day-before',
        calibration_date:moment().startOf("day").format("YYYY-MM-DD"),
        endOfRecurrence: null,
        endOfRecurrenceDate: null,
        selectedMonth: null,
        occurrencesInput: null,
        occurrencesDateTime: null,
        scheduleDateTable: null,
        scheduleDay: null,
        scheduleStatus: null,
        model: null,
        product_type: "certificates",
        equipment_type:null,
        reminder_date:null,
        reminder_date_value:null,
      },
      availableDates: [],
      selectMonth: ["3 Month", "6 Month", "9 Month", "12 Month","18 Month","24 Month"],
      selectDay: ["Sunday", "Monday", "Tuesday"],
      select_year: ["Exact Date", "1 Day Before", "None"],
    };
  },
  watch: {
    barcodeData(param) {
      this.barcode = param;

      // this.productCreate.barcode = param;
    },

    certificateCreateData: {
      deep: true,
      handler(param) {
          this.certificateCreate = param;
          this.getOptions();
          this.calculateDueDate();
          this.get_options();
          /*  if (param && this.lodash.isEmpty(param.warranty)) {
            this.warrantyActive = false;
            this.productCreate.warranty.push({
              id: null,
              value: null,
              field: null,
            });
          } */

        //this.partCreate.barcode = param.barcode ? param.barcode : this.barcode;

        /* this.$nextTick(() => {
            if (
              param.warranty &&
              param.warranty.length &&
              param.warranty[0] &&
              param.warranty[0].value
            ) {
              this.warrantyActive = true;
             
            }
          }); */
      },
    },

    certificateCreate: {
      deep: true,
      handler() {
        const _this = this;
        clearTimeout(_this.timeout);
        _this.timeout = setTimeout(function () {
          _this.$emit("saveCertificate", _this.certificateCreate);
        }, _this.timeoutLimit);

        if (_this.proDuplicateId) {
          //console.log(_this.proDuplicateId);
          this.certificateCreate.barcode = this.barcode;
        }
      },
    },

    /* "certificateCreate.product_type"() {
      this.getOptions();
    }, */
    
  },
  methods: {
    get_options() {
      this.$store
        .dispatch(QUERY, { url: "setting/calibration-month" })
        .then(({ data }) => {

          this.calibrationSchedules = data?data.calibrationSchedules:[];
          // this.certificateCreate.selectedMonth = null;
          // this.certificateCreate.calibration_date = null;
          // this.certificateCreate.due_date = null;
          // this.dueDate = null;
          //this.updateAvailableDates();
          // this.customerList = data.customers;
          // this.supplierList = data.suppliers;
          // this.all_property = data.properties;
          // if (this.customerId && this.isCustomer) {
          //   this.filter_property(this.customerId);
          // }
          // this.brands = data.brands;
          // this.categoryList = data.category;
          // this.products = data.products;
          // this.equipment_locations = data.equipment_locations;
          /* this.filtered_property = this.lodash.filter(this.all_property, {
            customer: this.asset.customer,
          }); */
        })
        .catch((error) => {
          this.logError(error);
        });
    },
    filterData($event){ //filter data based on equipment id
      filter(this.jobNumbersList, (row) => {
        if(row && $event==row.id){
          this.certificateCreate.serialNo = row.serial_no ? row.serial_no:null;
          this.certificateCreate.quotation_no = row.quotation_number?row.quotation_number:null;
          this.certificateCreate.nameOfCustomer = row.customer_name?row.customer_name:null;
          this.certificateCreate.equipment_type = row.equipement_type?row.equipement_type:null;
        }
				//return row.customer == customer;
			});
    },
    filterEquipments(event,field=''){
      //reset other fields
      if(field=='job_number')
      {
        this.resetAll();
      }

      const jobList = this.jobNumbersList;
      const jobId = event;
      
      this.equipmentsList = map(jobList, (row) => {
        if(row.job_order_no==jobId){
          return { text:row.equipment_id,value:row.id };
        }
			});
    },
    
    resetAll(){
      this.certificateCreate.tagging_id = null;
      this.certificateCreate.serialNo = null;
      this.certificateCreate.quotation_no = null;
      this.certificateCreate.nameOfCustomer = null;
      this.certificateCreate.equipment_type = null;
    },
    parseDate(dateString) {
      const parts = dateString.split(" - ");
      const datePart = parts[0];
      return new Date(datePart);
    },
    getDayFromDate(date) {
      const dayIndex = date.lastIndexOf("-") + 2;
      return date.substring(dayIndex).trim();
    },
    updateAvailableDates() {
      if (this.certificateCreate.selectedMonth) {
        const monthsToAdd = parseInt(this.certificateCreate.selectedMonth, 10);
        const currentDate = new Date();
        this.availableDates = Array.from(
          {
            length: monthsToAdd,
          },
          (_, index) => {
            const newDate = new Date(currentDate);
            newDate.setMonth(currentDate.getMonth() + index);

            const formattedDate = `${newDate.toLocaleDateString()} - ${newDate.toLocaleDateString(
              "en-US",
              { weekday: "long" }
            )}`;
            return formattedDate;

          }
        );

        this.calculateDueDate();
      }
    },
    clearAll() {
      this.dueSameDate = null;
      this.duePriorDate = null;
      this.dueDate=null;
      // console.log(this.dueDate,'due');
      // console.log(this.dueSameDate,'same');
      // console.log(this.duePriorDate,'prior');
    },
    calculateDueDate(event,type='') {
      // console.log(event,'event');
      // console.log(type,'type');
      if(this.certificateCreate.selectedMonth){

        let due_date = this.certificateCreate.due_date ? this.certificateCreate.due_date : null; // prior or same day
        let months = this.certificateCreate.selectedMonth;
        
        if(months){
          let calibrat_date = this.certificateCreate.calibration_date; //date
          if(type=='date'){
            calibrat_date = event ? event : null;
          }
          let calibrat_date_moment = moment(calibrat_date);
          
          //same day
          var days = calibrat_date_moment.add(months, "months");
          this.dueSameDate = days.format("YYYY-MM-DD");
          
          //prior
          this.duePriorDate = days.format("YYYY-MM-DD");
          var dueDateMoment = moment(this.duePriorDate).subtract(1, "days");
          this.duePriorDate = dueDateMoment.format("YYYY-MM-DD");

          switch(due_date){
            case 'same-day':
              this.dueDate = this.dueSameDate;
              // var days = calibrat_date_moment.add(months, "months");
              // this.dueDate = days.format("YYYY-MM-DD");
              break;
            case '1-day-before':
              this.dueDate = this.duePriorDate;
              // var dayss = calibrat_date_moment.add(months, "months");
              // this.dueDate = dayss.format("YYYY-MM-DD");
              // var dueDateMoment = moment(this.dueDate).subtract(1, "days");
              // this.dueDate = dueDateMoment.format("YYYY-MM-DD");
              break;
            case 'none':
              this.dueDate=null;
              break;  
          }

          this.certificateCreate.reminder_date = this.dueDate;
          this.certificateCreate.reminder_date_value = this.dueDate;

        }
        // console.log(this.dueDate,'due');
        // console.log(this.dueSameDate,'same');
        // console.log(this.duePriorDate,'prior');
      }
    },
    
    updateSelectedDate(date) {
      this.selectedDate = date;
    },
    /* UomData() {
      this.manageUnitDialog = true;
      this.getOptions();
    }, */
    manageLimit(e, value) {
      this.limitDecimal(e, value);
      if (value && value.length > 12) {
        e.preventDefault();
      }
    },
    updateImages(param) {
      this.certificateCreate.images = param;
    },
    updateAttachments(param) {
      this.certificateCreate.attachments = param;
    },
    // setDefaultDate(date) {
    //   this.defaultDate = moment(date).startOf("day").format("YYYY-MM-DD");
    //   if (this.$refs["calendar"]) {
    //     this.$refs["calendar"].checkChange();
    //   }
    // },
    getOptions() {
      // console.log(this.certificateId);
      const _this = this;
      let certId = _this.certificateId?_this.certificateId:0;
      _this.$store
        .dispatch(QUERY, {
          url: "certificate/options",
          data: { product_type: _this.certificateCreate.product_type,cert_id:certId },
        })
        .then((response) => {
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.barcode &&
            !this.certificateCreate.certificate_no
          ) {
            _this.certificateCreate.certificate_no = response.data.barcode;
          }
          _this.jobNumbersList = response.data.job_numbers;
          _this.pageLoading=false;
          
          if(_this.certificateCreate && _this.certificateCreate.job_no) //&& _this.$route.query.certificateId
          {
            _this.filterEquipments(_this.certificateCreate.job_no);
          }
          // console.log(this.certificateCreate);
          if(!this.certificateId){
           //console.log({aaaaa:this.calibrationSchedules})
            let defaultMonth = find(this.calibrationSchedules,(val) => val.value == "12");
            //console.log({defaultMonth});
            if(defaultMonth){
              this.certificateCreate.selectedMonth = "12";
              this.updateAvailableDates();
            }else{
              defaultMonth=this.calibrationSchedules[0].value;
              
              this.certificateCreate.selectedMonth = defaultMonth;
              this.updateAvailableDates();

            }
            
          }
          
          // if (
          //   _this.lodash.isEmpty(response.data) === false &&
          //   response.data.unit
          // ) {
          //   _this.unitList = response.data.unit;
          // }
          // if (!_this.updateMode) {
          //   if (
          //     !_this.parttId &&
          //     _this.lodash.isEmpty(response.data) === false &&
          //     response.data.barcode
          //   ) {
          //     _this.barcode = response.data.barcode;
          //     _this.certificateCreate.barcode = response.data.barcode;
          //   }
          //   if (_this.proDuplicateId) {
          //     _this.barcode = response.data.barcode;
          //     _this.certificateCreate.barcode = response.data.barcode;
          //   }
          //   if (_this.lodash.isEmpty(response.data.barcode_setting) === false) {
          //     _this.barcodeSetting = response.data.barcode_setting;
          //     //_this.certificateCreate.description = _this.barcodeSetting.description;
          //   }
          // }
        })
        .catch((error) => {
          _this.$store.dispatch(UPDATE_ERROR, error);
        });
    },
  },
  mounted() {
    if(!this.certificateId){
      this.getOptions();
    }
    //console.log(this.certificateId);
    
    let Config = AppConfiguration.get();
    this.calibrationSchedules = Config?Config.calibrationSchedules:[];
    this.calibrationDueDates = Config?Config.calibrationDueDates:[];
    
    // console.log(this.calibrationSchedules);
    //console.log(this.calibrationDueDates);
    //const jobId = this.certificateCreate.job_no;
    // console.log(this.certificateCreate);
    // this.filterEquipments(jobId);
  },
  created() {
    const _this = this;
    _this.proDuplicateId = _this.lodash.toSafeInteger(
      _this.$route.query.duplicate
    );
  },
};
</script>
